import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxRequiredRule = _resolveComponent("DxRequiredRule")!
  const _component_DxValidator = _resolveComponent("DxValidator")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!

  return (_openBlock(), _createBlock(_component_DxNumberBox, {
    value: _ctx.value,
    "show-spin-buttons": _ctx.showSpinButtons,
    "show-clear-button": _ctx.hasEmptyValue,
    hint: _ctx.hint,
    min: _ctx.min,
    max: _ctx.max,
    disabled: _ctx.disabled,
    readOnly: _ctx.readOnly,
    placeholder: _ctx.placeholder,
    step: _ctx.step,
    isValid: _ctx.isValid,
    format: _ctx.format,
    visible: _ctx.visible,
    onValueChanged: _ctx.valueChanged,
    onKeyDown: _ctx.keyDown,
    onOnContentReady: _ctx.contentReady,
    onOnEnterKey: _ctx.enterKey,
    onInitialized: _ctx.initialized
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxValidator, { "validation-group": _ctx.validationGroup }, {
        default: _withCtx(() => [
          (!_ctx.hasEmptyValue)
            ? (_openBlock(), _createBlock(_component_DxRequiredRule, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["validation-group"])
    ]),
    _: 1
  }, 8, ["value", "show-spin-buttons", "show-clear-button", "hint", "min", "max", "disabled", "readOnly", "placeholder", "step", "isValid", "format", "visible", "onValueChanged", "onKeyDown", "onOnContentReady", "onOnEnterKey", "onInitialized"]))
}
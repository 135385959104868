
import { DxNumberBox } from "devextreme-vue/number-box";
import { defineComponent } from "vue";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

export default defineComponent({
  components: {
    DxNumberBox,
    DxValidator,
    DxRequiredRule,
  },
  props: {
    visible: { type: Boolean, required: false, default: true },

    isValid: { type: Boolean, required: false, default: true },

    step: { type: Number, required: false, default: 1 },

    min: { type: Number, required: false, default: undefined },

    max: { type: Number, required: false, default: undefined },

    placeholder: { type: String, required: false, default: "" },

    readOnly: { type: Boolean, required: false, default: false },

    disabled: { type: Boolean, required: false, default: false },

    format: { type: String, required: false, default: "" },

    hasEmptyValue: { type: Boolean, required: false, default: false },

    showSpinButtons: { type: Boolean, required: false, default: true },

    hint: { type: String, required: false, default: undefined },

    value: { type: Number, required: false },

    //группа валидации (см. dxValidator.validationGroup)
    validationGroup: { required: false, default: "baseValidationGroup" },

    contentReady: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
    keyDown: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
    enterKey: {
      type: Function,
      required: false,
      default: () => {
        return;
      },
    },
  },
  methods: {
    valueChanged(e: any) {
      this.$emit("value-changed-evt", e);
    },

    initialized(e: any) {
      this.$emit("initialized-evt", e);
    },

    self() {
      return this as any;
    },
  },
  data() {
    return {
      disabledValue: false,
    };
  },
});
